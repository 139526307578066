var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCrearLiquidador",attrs:{"titulo":"Crear Liquidador","tamano":"modal-xl","no-aceptar":"","adicional":"Guardar"},on:{"adicional":_vm.postLiquidador}},[(_vm.cargando)?_c('cargando'):_vm._e(),_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 px-4 pl-5"},[_c('div',{staticClass:"col-auto"},[_c('slim-cropper',{ref:"fotoUser",staticClass:"border cr-pointer",staticStyle:{"height":"162px","width":"162px","background-color":"#F5F5F5","border-radius":"6px"},attrs:{"options":_vm.slimOptions}}),_c('p',{staticClass:"text-center text-gris2 f-15 mt-2"},[_vm._v(" Foto ")])],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Nombre ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:25","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"25","placeholder":"Nombre"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Cargo ")]),_c('ValidationProvider',{attrs:{"rules":"max:25","name":"Cargo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"25","placeholder":"Cargo"},model:{value:(_vm.cargo),callback:function ($$v) {_vm.cargo=$$v},expression:"cargo"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 my-4"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Correo electrónico de acceso ")]),_c('ValidationProvider',{attrs:{"rules":"email|max:70","name":"Correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"70","placeholder":"Correo electrónico"},model:{value:(_vm.correoElectronico),callback:function ($$v) {_vm.correoElectronico=$$v},expression:"correoElectronico"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"})]),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Contraseña de acceso ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:120","name":"Contraseña de acceso"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"120","placeholder":"Contraseña"},on:{"blur":_vm.verificarContrasenas},model:{value:(_vm.contrasena),callback:function ($$v) {_vm.contrasena=$$v},expression:"contrasena"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Repetir contraseña ")]),_c('ValidationProvider',{attrs:{"rules":"required|max:120","name":"Repetir contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"120","placeholder":"Repetir contraseña"},on:{"blur":_vm.verificarContrasenas},model:{value:(_vm.repContrasena),callback:function ($$v) {_vm.repContrasena=$$v},expression:"repContrasena"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('br'),(_vm.contrasenas_mal)?_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v("Contraseñas no coinciden")]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"row mx-0 pl-3 mb-3"},[_c('div',{staticClass:"col-auto px-2"}),_c('el-checkbox',{staticClass:"check-dark",model:{value:(_vm.tipoLiquidador),callback:function ($$v) {_vm.tipoLiquidador=$$v},expression:"tipoLiquidador"}}),_c('div',{staticClass:"col px-2 text-general f-15"},[_vm._v(" Tipo liquidador ")])],1),_c('div',{staticClass:"row mx-0 pl-3 mb-3"},[_c('div',{staticClass:"col-auto px-2"}),_c('el-checkbox',{staticClass:"check-dark",on:{"change":_vm.seleccionarTipoContacto},model:{value:(_vm.tipoContacto),callback:function ($$v) {_vm.tipoContacto=$$v},expression:"tipoContacto"}}),_c('div',{staticClass:"col px-2 text-general f-15"},[_vm._v(" Tipo contacto ")])],1),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Teléfono de contacto ")]),_c('ValidationProvider',{attrs:{"rules":"integer","name":"Teléfono de contacto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100 soloNumeros",attrs:{"size":"small","placeholder":"Teléfono","disabled":_vm.checkContacto},on:{"focus":_vm.soloNumeros,"change":_vm.validarTelefono},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-general f-12 pl-3"},[_vm._v(" Correo electrónico de contacto ")]),_c('ValidationProvider',{attrs:{"rules":"email|max:70","name":"Correo electrónico de contacto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small","max":"70","placeholder":"Correo electrónico","disabled":_vm.checkContacto},model:{value:(_vm.correoContacto),callback:function ($$v) {_vm.correoContacto=$$v},expression:"correoContacto"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }